<template>
  <div class="container">
    <div class="form-container">
      <div class="form-group">
          <label for="content">Content</label>
          <br>
          <textarea name="content" id="content" cols="50" rows="20" v-model="nav"></textarea>
      </div>
      <button @click="setNav">Update</button>
    </div>
    <div class="view-container">
      <button @click="getProjects" :disabled="busy">Show Projects</button>
      <ol v-for="d in data" :key="d">
        <li>{{d}}</li>
      </ol>

    </div>

  </div>
</template>

<script>

  import {ref} from 'vue'
  import axios from 'axios'
  import {getAuth} from '@/composables'

  export default {
    setup(){
      const nav = ref(null)
      const busy = ref(false)
      const data = ref([])
      
      const getNav = () => {
        axios.get(process.env.VUE_APP_BASE_URL+"/nav").then((res) => {
          // console.log(res.data)
          let json = JSON.stringify(res.data)
          json = json.replaceAll('[','[\n')
          json = json.replaceAll(']','\n]')
          json = json.replaceAll('{','\n\t{\n')
          json = json.replaceAll('}','\n\t}')
          json = json.replaceAll(',',',\n')
          nav.value = json
        })
      }
      getNav()

      const setNav = () => {
        let fd = new FormData()
        fd.append('content',nav.value)
        axios.post(process.env.VUE_APP_BASE_URL+"/nav/save",fd,getAuth()).then(() => {
          alert('Successfully Updated!')
        })
      }

      const getProjects = () => {
        busy.value = true
        axios.get(process.env.VUE_APP_BASE_URL+"/api/projects/all",getAuth()).then((res) => {
          for (let i of res.data){
            data.value.push('project/'+i.slug)
          }
          busy.value = true
        })
      }

      return {
        nav,setNav,getProjects,data
      }
    }
  }
</script>

<style>
  .container{
    display: flex;
  }
  .view-container {
    margin-left: 15px;
  }
</style>